
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps")

const ENTITYID="nntagexclusive"




const nncoursepickerfields = [
	{"label":"Series", "dbfield": "nncourseseries_name", "type": "text", "filtertype": "textbox"},
	{"label":"Course", "dbfield": "nncourse_name", "type": "text", "filtertype": "textbox"},
];

const nncoursepickerfilter = [
	//{"field":"nnfranchise_id", "operation": ">", "value": 0},
];

const nnfranchisepickerfields = [
	{"label":"Logo", "dbfield": "nnfranchisepackage_franchiselogo", "type": "image", "filtertype": "image"},
	{"label":"Name", "dbfield": "nnfranchisepackage_name", "type": "text", "filtertype": "textbox"}
];

const nnfranchisepickerfilter = [
	//{"field":"nnfranchise_id", "operation": ">", "value": 0},
];


const nnseminarpickerfields = [
	{"label":"Name", "dbfield": "nnseminar_name", "type": "text", "filtertype": "textbox"},
	{"label":"Logo", "dbfield": "nnseminar_banner", "type": "image", "filtertype": "image"}
];

const nnseminarpickerfilter = [
	//{"field":"nnseminar_id", "operation": ">", "value": 0},
];



const searchFields = [
	{"label":"Start", "dbfield": "nntagexclusive_startdatetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"End", "dbfield": "nntagexclusive_enddatetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Name", "dbfield": "nntagexclusive_name", "type": "text", "filtertype": "text"},
	{"label":"Description", "dbfield": "nntagexclusive_description", "type": "text", "filtertype": "text"},
];


const commonFormFields = [
	{"label":"Name", "field": "nntagexclusive_name", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Description", "field": "nntagexclusive_description", "value": "", "input": "textarea", "mode": "normal"},
	{"label":"Start Date", "field": "nntagexclusive_startdatetime", "value": "", "input": "datetime", "mode": "required"},
	{"label":"End Date", "field": "nntagexclusive_enddatetime", "value": "", "input": "datetime", "mode": "required"},
];

const newFormFields = [
	[
		{"label":"Code", "field": "nntagexclusive_code", "value": "", "input": "textbox", "mode": "unique", "info":"Usually YYYYMMDD-url-details for seminars, franchise-url for franchise"},
	]
];

const formFields = [
	[
		{"label":"Code", "field": "nntagexclusive_code", "value": "", "input": "textbox", "mode": "readonly"},
	]
];


const subformFields = [
	{
		"subformid": "seminarlist",
		"label": "Seminars",
		"table": "nnseminarexclusive",
		"sort": "nnseminarexclusive_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name",
				"field": "nnseminar_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nnseminarpickerfields,
				"pickerfilters": nnseminarpickerfilter

			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "franchiselist",
		"label": "Franchise Package",
		"table": "nnfranchiseexclusive",
		"sort": "nnfranchiseexclusive_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name",
				"field": "nnfranchisepackage_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nnfranchisepickerfields,
				"pickerfilters": nnfranchisepickerfilter
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "courselist",
		"label": "Coures",
		"table": "nncourseexclusive",
		"sort": "nncourseexclusive_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name",
				"field": "nncourse_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nncoursepickerfields,
				"pickerfilters": nncoursepickerfilter
			},
		],
		"footerlist": [
		]
	},
];




const TagexclusivePage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		// Load before and after
		getTaggedentityIdlist(pkid, "franchise", function(franchiseidlist) {
			getTaggedentityIdlist(pkid, "seminar", function(seminaridlist) {
				webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
					if (response.status === "OK") {
						updateEntityUsers(response.documentid, "franchise", franchiseidlist, function(franchiseres) {
							updateEntityUsers(response.documentid, "seminar", seminaridlist, function(seminarres) {
								callback(response);
							});
						});
					} else {
						callback(response);
					}
				});

			});
		});

		//callback({"status":"Error", "message":"Debug"}); return;
	}

	function getTaggedentityIdlist(nntagexclusive_id, target, callback)
	{
		if (nntagexclusive_id < 1) {
			callback([]);
			return;
		}
		const targetpkidfield = "nn"+target+"_id";
		// Generate user list of selected franchise/seminar
		webappsAPI.loadData("nn"+target+"exclusive", {
			"dbfieldlist":[targetpkidfield],
			"filters":[[{
				field: "nntagexclusive_id",
				operation: "=",
				value: nntagexclusive_id
			}]]
		}, token).then(dataresponse => {
			if (dataresponse.status === "OK") {
				var idlist = [];
				var idx = 0;
				while (idx < dataresponse.data.length) {
					idlist.push(dataresponse.data[idx][targetpkidfield]);
					idx++;
				}
				callback(idlist);
				return;
			}
			callback([]);
		});
	}

	function updateEntityUsers(nntagexclusive_id, target, idlist, callback)
	{
		getTaggedentityIdlist(nntagexclusive_id, target, function(addidlist) {
			var addidlistidx = 0;
			while (addidlistidx < addidlist.length) {
				if (!idlist.includes(addidlist[addidlistidx])) {
					idlist.push(addidlist[addidlistidx]);
				}
				addidlistidx++;
			}
			if (idlist.length > 0) {
				negosyonowAPI.buildEntityIdList(idlist,token, target).then(nnresponse => {
					callback(nnresponse);
				});
				return;
			}
			callback({"status":"OK", "message":"Error/No IDs affected"});
		});
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nntagexclusive_name"]}
				searchFields={searchFields}
				userSearchParam={{"orderby":"nntagexclusive_startdatetime desc"}}
				editFields={[formFields[0].concat(commonFormFields)]}
				newformFields={[newFormFields[0].concat(commonFormFields)]}
				editSubFormFields={subformFields}
				newSubformFields={[]}
				customSubmit={customSubmit}
				token={token} />
		</Layout>

}


export default TagexclusivePage;
